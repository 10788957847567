var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-layout',{attrs:{"wrap":"","justify-start":"","pa-md-0":"","pt-5":"","pl-5":"","pr-5":""}},[_c('v-flex',{staticStyle:{"padding-left":"40px","padding-right":"40px","padding-top":"50px"},attrs:{"xs12":"","align-self-center":""}},[_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{style:(_vm.$vuetify.breakpoint.name == 'xs'
              ? 'margin-top:0%'
              : 'padding-top:0%'),attrs:{"xs12":"","lg12":"","align-self-center":""}},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":"","align-self-center":"","text-center":""}},[_c('span',{staticClass:"poppinsbold",staticStyle:{"font-size":"24px","color":"#000"}},[_vm._v(" Resource Centre ")])])],1),_c('v-layout',{attrs:{"wrap":"","justify-start":""}},[_c('v-flex',{attrs:{"xs12":"","text-left":""}},[_c('span',{staticClass:"poppinssemibold",staticStyle:{"color":"#000000","font-size":"20px"}},[_vm._v("Activities")]),_c('v-layout',{attrs:{"wrap":"","justify-start":"","pt-4":""}},_vm._l((_vm.items1),function(item,i){return _c('v-flex',{key:i,attrs:{"xs12":"","pb-5":""}},[_c('v-card',{staticStyle:{"border":"1px solid"},attrs:{"outlined":""}},[_c('v-layout',{attrs:{"wrap":"","justify-start":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-layout',{staticClass:"hidden-sm-and-down",attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":"","sm2":"","md2":"","lg1":"","xl1":"","text-left":""}},[_c('v-img',{style:({
                                height:
                                  _vm.$vuetify.breakpoint.name == 'md'
                                    ? '220px'
                                    : _vm.$vuetify.breakpoint.name == 'lg'
                                    ? '150px'
                                    : '250px',
                              }),attrs:{"src":item.image}})],1),_c('v-flex',{attrs:{"xs12":"","sm10":"","md10":"","pa-3":""}},[_c('span',{staticClass:"poppinssemibold",staticStyle:{"color":"#000000","font-size":"14px"}},[_c('u',[_c('a',{attrs:{"href":item.link,"target":"_blank"}},[_vm._v(" "+_vm._s(item.name))])])]),_c('br'),_c('br'),_c('span',{staticClass:"poppinsregular",staticStyle:{"font-size":"14px","color":"#000000"}},[_vm._v(_vm._s(item.text))])])],1),_c('v-layout',{staticClass:"hidden-md-and-up",attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":"","sm6":"","text-left":""}},[_c('v-img',{attrs:{"src":item.image}})],1),_c('v-flex',{attrs:{"xs12":"","sm6":"","pa-3":""}},[_c('span',{staticClass:"poppinssemibold",staticStyle:{"color":"#000000","font-size":"14px"}},[_c('u',[_c('a',{attrs:{"href":item.link,"target":"_blank"}},[_vm._v(" "+_vm._s(item.name))])])]),_c('br'),_c('br'),_c('span',{staticClass:"poppinsregular",staticStyle:{"font-size":"14px","color":"#000000"}},[_vm._v(_vm._s(item.text))])])],1)],1)],1)],1)],1)}),1)],1)],1),_c('v-layout',{attrs:{"wrap":"","justify-start":""}},[_c('v-flex',{attrs:{"xs12":"","text-left":""}},[_c('span',{staticClass:"poppinssemibold",staticStyle:{"color":"#000000","font-size":"20px"}},[_vm._v("Comics")]),_c('v-layout',{attrs:{"wrap":"","justify-start":"","pt-4":""}},_vm._l((_vm.items2),function(item,i){return _c('v-flex',{key:i,attrs:{"xs12":"","pb-5":""}},[_c('v-card',{staticStyle:{"border":"1px solid"},attrs:{"outlined":""}},[_c('v-layout',{attrs:{"wrap":"","justify-start":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-layout',{staticClass:"hidden-sm-and-down",attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":"","sm2":"","md2":"","lg1":"","xl1":"","text-left":""}},[_c('v-img',{style:({
                                height:
                                  _vm.$vuetify.breakpoint.name == 'md'
                                    ? '220px'
                                    : _vm.$vuetify.breakpoint.name == 'lg'
                                    ? '150px'
                                    : '250px',
                              }),attrs:{"src":item.image}})],1),_c('v-flex',{attrs:{"xs12":"","sm10":"","md10":"","pa-3":""}},[_c('span',{staticClass:"poppinssemibold",staticStyle:{"color":"#000000","font-size":"14px"}},[_c('u',[_c('a',{attrs:{"href":item.link,"target":"_blank"}},[_vm._v(" "+_vm._s(item.name))])])]),_c('br'),_c('br'),_c('span',{staticClass:"poppinsregular",staticStyle:{"font-size":"14px","color":"#000000"}},[_vm._v(_vm._s(item.text))])])],1),_c('v-layout',{staticClass:"hidden-md-and-up",attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":"","sm6":"","text-left":""}},[_c('v-img',{attrs:{"src":item.image}})],1),_c('v-flex',{attrs:{"xs12":"","sm6":"","pa-3":""}},[_c('span',{staticClass:"poppinssemibold",staticStyle:{"color":"#000000","font-size":"14px"}},[_c('u',[_c('a',{attrs:{"href":item.link,"target":"_blank"}},[_vm._v(" "+_vm._s(item.name))])])]),_c('br'),_c('br'),_c('span',{staticClass:"poppinsregular",staticStyle:{"font-size":"14px","color":"#000000"}},[_vm._v(_vm._s(item.text))])])],1)],1)],1)],1)],1)}),1)],1)],1),_c('v-layout',{attrs:{"wrap":"","justify-start":""}},[_c('v-flex',{attrs:{"xs12":"","text-left":""}},[_c('span',{staticClass:"poppinssemibold",staticStyle:{"color":"#000000","font-size":"20px"}},[_vm._v("Magazines")]),_c('v-layout',{attrs:{"wrap":"","justify-start":"","pt-4":""}},_vm._l((_vm.items3),function(item,i){return _c('v-flex',{key:i,attrs:{"xs12":"","pb-5":""}},[_c('v-card',{staticStyle:{"border":"1px solid"},attrs:{"outlined":""}},[_c('v-layout',{attrs:{"wrap":"","justify-start":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-layout',{staticClass:"hidden-sm-and-down",attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":"","sm2":"","md2":"","lg1":"","xl1":"","text-left":""}},[_c('v-img',{style:({
                                height:
                                  _vm.$vuetify.breakpoint.name == 'md'
                                    ? '220px'
                                    : _vm.$vuetify.breakpoint.name == 'lg'
                                    ? '150px'
                                    : '250px',
                              }),attrs:{"src":item.image}})],1),_c('v-flex',{attrs:{"xs12":"","sm10":"","md10":"","pa-3":""}},[_c('span',{staticClass:"poppinssemibold",staticStyle:{"color":"#000000","font-size":"14px"}},[_c('u',[_c('a',{attrs:{"href":item.link,"target":"_blank"}},[_vm._v(" "+_vm._s(item.name))])])]),_c('br'),_c('br'),_c('span',{staticClass:"poppinsregular",staticStyle:{"font-size":"14px","color":"#000000"}},[_vm._v(_vm._s(item.text))])])],1),_c('v-layout',{staticClass:"hidden-md-and-up",attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":"","sm6":"","text-left":""}},[_c('v-img',{attrs:{"src":item.image}})],1),_c('v-flex',{attrs:{"xs12":"","sm6":"","pa-3":""}},[_c('span',{staticClass:"poppinssemibold",staticStyle:{"color":"#000000","font-size":"14px"}},[_c('u',[_c('a',{attrs:{"href":item.link,"target":"_blank"}},[_vm._v(" "+_vm._s(item.name))])])]),_c('br'),_c('br'),_c('span',{staticClass:"poppinsregular",staticStyle:{"font-size":"14px","color":"#000000"}},[_vm._v(_vm._s(item.text))])])],1)],1)],1)],1)],1)}),1)],1)],1),_c('v-layout',{attrs:{"wrap":"","justify-start":""}},[_c('v-flex',{attrs:{"xs12":"","text-left":""}},[_c('span',{staticClass:"poppinssemibold",staticStyle:{"color":"#000000","font-size":"20px"}},[_vm._v("Posters")]),_c('v-layout',{attrs:{"wrap":"","justify-start":"","pt-4":""}},_vm._l((_vm.items4),function(item,i){return _c('v-flex',{key:i,attrs:{"xs12":"","pb-5":""}},[_c('v-card',{staticStyle:{"border":"1px solid"},attrs:{"outlined":""}},[_c('v-layout',{attrs:{"wrap":"","justify-start":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-layout',{staticClass:"hidden-sm-and-down",attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":"","sm2":"","md2":"","lg1":"","xl1":"","text-left":""}},[_c('v-img',{style:({
                                height:
                                  _vm.$vuetify.breakpoint.name == 'md'
                                    ? '220px'
                                    : _vm.$vuetify.breakpoint.name == 'lg'
                                    ? '150px'
                                    : '250px',
                              }),attrs:{"src":item.image}})],1),_c('v-flex',{attrs:{"xs12":"","sm10":"","md10":"","pa-3":""}},[_c('span',{staticClass:"poppinssemibold",staticStyle:{"color":"#000000","font-size":"14px"}},[_c('u',[_c('a',{attrs:{"href":item.link,"target":"_blank"}},[_vm._v(" "+_vm._s(item.name))])])]),_c('br'),_c('br'),_c('span',{staticClass:"poppinsregular",staticStyle:{"font-size":"14px","color":"#000000"}},[_vm._v(_vm._s(item.text))])])],1),_c('v-layout',{staticClass:"hidden-md-and-up",attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":"","sm6":"","text-left":""}},[_c('v-img',{attrs:{"src":item.image}})],1),_c('v-flex',{attrs:{"xs12":"","sm6":"","pa-3":""}},[_c('span',{staticClass:"poppinssemibold",staticStyle:{"color":"#000000","font-size":"14px"}},[_c('u',[_c('a',{attrs:{"href":item.link,"target":"_blank"}},[_vm._v(" "+_vm._s(item.name))])])]),_c('br'),_c('br'),_c('span',{staticClass:"poppinsregular",staticStyle:{"font-size":"14px","color":"#000000"}},[_vm._v(_vm._s(item.text))])])],1)],1)],1)],1)],1)}),1)],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }